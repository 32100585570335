<template>
  <div class="outBox">
    <rxNavBar title="试岗任务审核"></rxNavBar>
    <van-tabs v-model="active"
              line-width="20"
              line-height="5"
              title-active-color="rgb(255,81,45)"
              sticky
              offset-top="1.33rem" @scroll="scroll"
    >
      <van-tab title="详情">
        <div id="planInfoPreview"></div>
        <div class="learningAttachmentsTitle">学习附件</div>
        <div class="learningAttachments">
          <van-uploader accept=""
                        v-model="fileList"
                        upload-icon="plus"
                        multiple
                        image-fit="contain"
                        @click-preview="clickFile"
                        disabled
          >
          </van-uploader>
        </div>
      </van-tab>
      <van-tab title="审批">
        <div class="approve">
          <div class="time_line">
            <div class="item">
              <div class="left">
                <div :class="{line:true ,line_top:true,first_top:true}"></div>
                <div class="point"></div>
                <div class="line line_bottom"></div>
              </div>
              <div class="right">
                <div class="approve_panel">
                  <div class="total_color">
                    申请
<!--                    <span class="floatR small_font">{{approvalHistroyInfo.applicationtime}}</span>-->
                  </div>
                  <div class="approve_Text">
                    {{approvalHistroyInfo.staffName}}
                  </div>
                </div>
              </div>
            </div>

            <div class="item" v-for="(item,index) in approvalHistroyList" :key="index">
              <div class="left">
                <div :class="{line:true ,line_top:true,first_top:false}"></div>
                <div class="point"></div>
                <div class="line line_bottom" v-if="index != approvalHistroyList.length - 1"></div>
              </div>
              <div class="right">
                <div class="approve_panel">
                  <div class="total_color">
                    {{item.approvalResultStr}}
                    <span style="display: inline-block;margin-left: 0.2rem">{{item.approvalTypeStr}}</span>
                    <span class="floatR small_font">{{item.addTime}}</span>
                  </div>
                  <div class="approve_Text">
                    {{item.staffName}}
                    <span style="display: inline-block;margin-left: 0.2rem">{{item.remarks}}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="item" v-if="status=='0' || status=='1'">
              <div class="left">
                <div :class="{line:true ,line_top:true,first_top:false}"></div>
                <div class="point"></div>
                <div class="line line_bottom"></div>
              </div>
              <div class="right">
                <div class="approve_panel">
                  <div class="total_color">
                    待审批
                    <span style="display: inline-block;margin-left: 0.2rem">待上级审</span>
                  </div>
                  <div class="approve_Text">
                    {{seniorStaffName}}
                  </div>
                </div>
              </div>
            </div>
            <div class="item" v-if="status=='0' || status=='1' || status=='2'">
              <div class="left">
                <div :class="{line:true ,line_top:true,first_top:false}"></div>
                <div class="point"></div>
                <div class="line line_bottom"></div>
              </div>
              <div class="right">
                <div class="approve_panel">
                  <div class="total_color">
                    待审批
                    <span style="display: inline-block;margin-left: 0.2rem">待上上级审</span>
                  </div>
                  <div class="approve_Text">
                    {{superiorStaffName}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab title="预约考试">
        <div class="approve">
          <div class="time_line">
            <div class="item" v-for="(item,index) in quizAppointmentList" :key="index">
              <div class="left">
                <div :class="{line:true ,line_top:true,first_top:index==0}"></div>
                <div class="point"></div>
                <div class="line line_bottom" v-if="index != quizAppointmentList.length - 1"></div>
              </div>
              <div class="right">
                <div class="approve_panel">
                  <div class="total_color">
                    {{item.statusStr}}
                    <!--                    <span style="display: inline-block;margin-left: 0.2rem">{{item.approvalTypeStr}}</span>-->
                    <span class="floatR small_font">{{item.appointmentTime}}</span>
                  </div>
                  <div class="approve_Text">
                    <span style="display: inline-block;" v-if="item.status=='1' || item.status=='4'">{{item.reason}}</span>
                    <div v-if="item.status=='2'||item.status=='3'||item.status=='5'">{{item.score?'成绩上传时间：':'审批时间：'}}{{item.actualTime}}</div>
                    <div v-if="(item.status=='2'||item.status=='3'||item.status=='5')&&item.score">{{item.score}}</div>
                    <div v-if="item.orderPlacedTime">开单时间：{{item.orderPlacedTime}}</div>

                    <div v-if="item.picList!=undefined && item.picList.length!==0">
                      <span v-for="(picItem,picIndex) in item.picList" :key="picItem.path">
                        <van-image fit="contain" :width="60" :height="60" :src="picItem.path" radius="5px" @click="openImg(item.picList,picIndex)" class="head_sculpture"/>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-tab>
    </van-tabs>
    <div v-if="enterType==='approve'">
      <div class="learningAttachmentsTitle">审批结果</div>
      <div id="cleanId" class="part-inputpart-row" @click="isCleanTypeShow = !isCleanTypeShow">
        <span :class="!approveResult.approvalStatusStr? 'notdot' : 'isdot'" > </span>
        <span class="part-inputpart-row-header">结果</span>
        <span class="divide">|</span>
        <span class="part-inputpart-row-graytext" :class="approveResult.approvalStatusStr ? 'part-inputpart-row-normaltext':'' ">{{approveResult.approvalStatusStr}}</span>
        <img class="part-inputpart-row-right" :class=" isCleanTypeShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' " src="../../../assets/images/triangle.png">
      </div>
      <van-popup v-model="isCleanTypeShow" position="bottom">
        <van-picker
            show-toolbar
            :columns="approveResultList"
            @cancel="isCleanTypeShow = false"
            :default-index="approveResult.approvalStatus!=''?Number(approveResult.approvalStatus)-1:0"
            value-key="dictionaryTitle"
            @confirm="selectApprovalStatusStr"
        />
      </van-popup>
      <div class="approveOpinion">
        <p class="yijian" style="font-size: 15px;font-weight: bold">审批意见(必填)</p>
        <div class="text_body">
          <van-field v-model="approveResult.approvalOpinion"  type="textarea" class="mes_body" rows="3"
                     autosize/>
        </div>
      </div>
      <div class="sub_btn">
        <van-button  :class="checkApprove()?'saveButton_Enable':'saveButton_Disable'" :disabled="!checkApprove()||disabled" size="large" @click="save()">保存
        </van-button>
      </div>
    </div>

    <!--底部固定栏-->
        <div class="bottom-fixed">
    <!--      <div @click="save" class="bottom-fixed-phoneRelation">保存</div>-->
          <div @click="standardAnswer" :class="'bottom-fixed-orderLook'">标准答案</div>
        </div>
    <van-popup v-model="answerShow" position="bottom" :style="{ height: '60%',backgroundColor:'#f8f8f8' }" >
      <img style="float: left;margin: 20px 5px 0 15px;width: 20px;height: 20px;" src="../../../assets/images/testImgAss/shoufangshenqing_1.png">
      <div style="margin-top: 18px;margin-bottom: 10px;font-size: 18px;font-weight: bold;color: rgb(255, 75, 39)">答案比对</div>
      <div class="answerBox">
        <div v-for="item in planDetailList" :key="item.triId" class="answer">
          <div class="answerTitle">
            {{item.sequence}}、{{item.detailName}}:
          </div>
          <div class="contentText">
            员工作答：<span>{{item.content}}</span>
          </div>
          <div class="answerText">
            标准答案：<span>{{item.answer}}</span>
          </div>

        </div>
      </div>
    </van-popup>
    <van-loading
        class="vanLoading"
        type="spinner"
        color="#1989fa"
        v-if="loadingFlag"
    />
  </div>
</template>

<script>
import {
  Button,
  Divider,
  DropdownItem,
  DropdownMenu, Field, Image as VanImage, ImagePreview,
  List, Loading,
  NavBar,
  Picker,
  Popup,
  PullRefresh,
  Search, Tab, Tabs,
  uploader
} from "vant";
import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
import {checkAndroid, checkIOS, getStaffId, responseUtil} from "../../../libs/rongxunUtil";
import {
  approvalTrialPlan,
  queryApprovalHistory,
  queryQuizAppointment,
  trialplanrecord,
} from "../../../getData/getData";

export default {
  name: "probationPlanApprove",
  components: {
    [Button.name]:Button,
    [Search.name]:Search,
    [DropdownMenu.name]:DropdownMenu,
    [DropdownItem.name]:DropdownItem,
    [Divider.name]:Divider,
    [NavBar.name]: NavBar,
    [List.name]: List,
    rxNavBar,
    [Picker.name]: Picker,
    [Popup.name]: Popup,
    [PullRefresh.name]: PullRefresh,
    [uploader .name]:uploader,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Field.name]: Field,
    [VanImage.name]: VanImage,
    [Loading.name]:Loading,
  },
  data(){
    return{
      androidFlg:'',
      active: 0,
      offsetTop:'',
      fileList:[],
      planDetailList:[],
      approvalHistroyList:[],
      approvalHistroyInfo:{},
      quizAppointmentList:[],
      isCleanTypeShow:false,
      // 1通过 2驳回 3拒绝   同意入职，重新试岗，拒绝入职
      approveResultList:[
        {
          dictionaryValue:'1',
          dictionaryTitle:'同意入职'
        },
        {
          dictionaryValue:'2',
          dictionaryTitle:'重新试岗'
        },
        {
          dictionaryValue:'3',
          dictionaryTitle:'拒绝入职'
        },
      ],
      approveResult:{
        approvalStatus:'',
        approvalStatusStr:'',
        approvalOpinion:'',
      },
      enterType:'',
      seniorStaffName:'',
      superiorStaffName:'',
      status:'',
      disabled:false,
      answerShow:false,
      loadingFlag:false,
    }
  },
  activated() {
    this.active = 0
    this.enterType = this.$route.query.enterType
    this.seniorStaffName = this.$route.query.seniorStaffName
    this.superiorStaffName = this.$route.query.superiorStaffName
    this.status = this.$route.query.status
    this.approveResult = this.$options.data().approveResult
    this.checkPhoneorMobel()
    this.initProbationPlanDetail()
    this.queryApprovalHistory()
    this.queryQuizAppointment()
  },
  mounted() {
    this.active = 0
    this.enterType = this.$route.query.enterType
    this.seniorStaffName = this.$route.query.seniorStaffName
    this.superiorStaffName = this.$route.query.superiorStaffName
    this.status = this.$route.query.status
    this.approveResult = this.$options.data().approveResult

    this.checkPhoneorMobel()
    this.initProbationPlanDetail()
    this.queryApprovalHistory()
    this.queryQuizAppointment()
  },
  methods:{
    checkPhoneorMobel() {
      if(checkAndroid()) {
        this.androidFlg = true
      }
      else if(checkIOS()) {
        this.androidFlg = false
      }
    },
    initProbationPlanDetail(){
      let that = this
      let initData = {}
      initData.user_id = getStaffId()
      initData.mainTrialplanRecord_id = this.$route.query.id
      this.trialplanrecordList = []
      let container = document.getElementById("planInfoPreview");
      if(container!=null){
        container.innerHTML = ''
      }
      trialplanrecord(initData).then(function (response) {
        that.fileList = []  //附件
        for(let i in response.data.data.fileList){
          let map = {}
          map.url = response.data.data.fileList[i].path
          map.id = response.data.data.fileList[i].id
          map.file = new File([], response.data.data.fileList[i].name, {})
          map.deletable = false
          that.fileList.push(map)
        }

        let content = response.data.data.probationPlan.content  //主文本
        let planDetailList = response.data.data.trialplanrecordList  //替换码
        that.planDetailList = response.data.data.trialplanrecordList  //替换码

        for(let i in planDetailList){
          let charToReplace = planDetailList[i].substitution; // 要被替换的字符
          let charToReplaceStr = (planDetailList[i].substitution).substring(2, planDetailList[i].substitution.length - 2); // 要被替换的绑定键
          let ownContent = planDetailList[i].content!=undefined&&planDetailList[i].content!==''?planDetailList[i].content:'';  //作答的填写
          let replacementHTML = `<span class="divSpanBox">【${ownContent}】</span>`
          content = content.replace(charToReplace,replacementHTML)
        }
        let newParagraph = document.createElement("p")  //创建内容元素节点
        newParagraph.insertAdjacentHTML("beforeend",content);  //放HTML  //在元素里最后一个节点后 插入一个html
        let container = document.getElementById("planInfoPreview");
        container.appendChild(newParagraph);

        //赋值class，写样式
        for(let j in document.getElementsByTagName("p")){
          if(typeof document.getElementsByTagName("p")[j]=='object'){
            document.getElementsByTagName("p")[j].className = 'divBox'
          }
        }
      })

    },
    queryApprovalHistory(){
      let that = this
      let initData = {}
      initData.staff_id = getStaffId()
      initData.mainTrialplanRecord_id = this.$route.query.id
      queryApprovalHistory(initData).then(function (response){
        that.approvalHistroyList = response.data.data.data?response.data.data.data:[]
        that.approvalHistroyInfo = response.data.data
      })
    },
    queryQuizAppointment(){
      let that = this
      let initData = {}
      initData.staff_id = getStaffId()
      initData.mainTrialplanRecord_id = this.$route.query.id
      queryQuizAppointment(initData).then(function (response){
        that.quizAppointmentList = response.data.data.data
      })
    },
    openImg(picList,startIndex) {
      let arr = []
      for(let i in picList){
        arr.push(picList[i].path)
      }
      ImagePreview({
        images: arr,
        startPosition: startIndex,
        onClose() {},
      })
    },
    //点击查看文件
    clickFile(file){
      let extensionThr = file.url.substr(-3)
      let extensionFour = file.url.substr(-4)
      if(extensionThr==='txt' || extensionThr==='xls' || extensionThr==='doc' || extensionFour==='xlsx' || extensionFour==='docx'){
        this.$router.push({
          name:'electronicContract',
          query:{
            type:'ssqian',
            electronicContractUrl: file.url,
            title:file.file.name,
          }
        });
      }else if(extensionThr==='pdf'){
        this.$router.push({
          name:'electronicContract',
          query:{
            type:'fxqian',  //pdf的标识
            electronicContractUrl: file.url,
            title:file.file.name
          }
        });
      }else if(extensionThr==='png'|| extensionThr==='jpg'){
        return;
      }else{
        // responseUtil.alertMsg(this,'暂不支持打开此类文件')
        // return
      }
    },
    // 滚动时获取nav高度并赋值
    scroll() {
      if (this.$refs.navBar) {
        this.offsetTop = this.$refs.navBar.clientHeight
      }
    },
    selectApprovalStatusStr(item,index){
      this.isCleanTypeShow = false
      this.approveResult.approvalStatus= item.dictionaryValue
      this.approveResult.approvalStatusStr= item.dictionaryTitle

    },


    save(){
      if(this.disabled){
        return
      }
      let that = this
      let initData = {}
      initData.approvalOpinion = this.approveResult.approvalOpinion
      initData.approvalStatus = this.approveResult.approvalStatus
      initData.mainTrialplanRecord_id = this.$route.query.id
      initData.staff_id = getStaffId()
      initData.level = this.$route.query.status
      this.disabled = true
      this.loadingFlag = true
      approvalTrialPlan(initData).then(function (response){
        that.disabled = false
        that.loadingFlag = false
        responseUtil.alertOK(that)
          that.$router.go(-1)
      })
    },
    checkApprove(){
      if(this.approveResult.approvalOpinion!=''&&this.approveResult.approvalStatus!=''){
        return true
      }else{
        return false
      }
    },

    standardAnswer(){
        this.answerShow = true
    },
  }

}
</script>

<style scoped lang="less">
*{
  margin: 0px;
  padding: 0px;
}
.van-tab__pane{
  margin-bottom: 70px;
}
#planInfoPreview{
  box-sizing: border-box;
  margin: 0 0.3rem;
  padding: 0.2rem;
  background-color: white;
  border-radius: 0.5rem;
}
.learningAttachmentsTitle {
  padding: 5px 0px;
  color: rgb(244, 99, 76);
  margin: 25px 0.3rem 0rem 0.4rem;
  font-weight: 700;
  font-size: 12px;
}
.learningAttachments{
  box-sizing: border-box;
  margin: 0 0.3rem;
  padding: 0.4rem;
  background-color: white;
  border-radius: 0.5rem;
}


.part-inputpart-row-header{
  font-weight: bold;
  font-size: 14px;
  width: 90px;
  color: black;
}
.part-inputpart-row-graytext {
  color: #d9d9d9;
  width: 100%;
  font-size: 14px;
}
.part-inputpart-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  font-size: 0.37rem;
  text-align: center;
  overflow: hidden;
  box-sizing: border-box;
  margin: 0 0.3rem 0.4rem;
  background-color: white;
  border-radius: 0.2rem;
}
.notdot {
  display: inline-block;
  padding: 3.5px;
  border-radius: 50%;
  background-color: rgb(136, 136, 136);
  vertical-align: middle;
  margin-right: 13px;
  margin-left: 15px;
}
.isdot{
  display: inline-block;
  width: 8px;
  height: 6px;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 13px;
  background-color: rgb(251,91,64);
  margin-left: 15px;
}
.part-inputpart-row-normaltext{
  color: black;
}
.part-inputpart-row-right-downArrow{
  width: 9px;
  height: 9px;
  margin-right: 15px;
}
/*选择三角图标的向上向下样式*/
.part-inputpart-row-right-upArrow{
  width: 9px;
  height: 9px;
  /*transform: rotateX(180deg);*/
  margin-right: 15px;
}
.approveOpinion{
  box-sizing: border-box;
  margin: 0 0.3rem;
  padding: 0.4rem;
  background-color: white;
  border-radius: 0.2rem;
}
.yijian{
  font-size: 15px;
}
.text_body {
  //padding: 15px;
  margin-top: 0.4rem;
  .mes_body {
    border-radius: 8px;
    padding: 0.2rem;
    background-color: rgb(250, 250, 250);
  }
}

::v-deep .divBox{
  font-size: 16px;
}
::v-deep .divSpanBox{
  color: blue;
  text-decoration: underline;
}
::v-deep img{
  width: 100%;
  height: auto;
}
.sub_btn {
  margin: 0.8rem auto 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.saveButton_Enable{
  background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;
  color: white;
  width: 345px;
  height: 50px;
  border-radius: 8px;
  font-size: 18px;
  line-height: 1.22rem;
  text-align: center;
  margin: 0.1rem 0 0.3rem;
}
.saveButton_Disable{
  display: block;
  background-color: rgba(184, 184, 184, 0.2);
  color: white;
  width: 345px;
  height: 50px;
  border-radius: 8px;
  font-size: 18px;
  line-height: 1.22rem;
  text-align: center;
  margin: 0.1rem;
}


// 时间线
.approve {
  margin-top: 20px;
  font-size: 12px;
  .time_line {
    .item {
      display: flex;
      align-items: stretch;
      justify-content: space-between;

      .left {
        width: 20px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 15px;
        /*margin-right: 5px;*/

        .line {
          width: 1px;
          background-color: rgb(255, 75, 39);
          position: absolute;

          &.line_top {
            top: 0px;
            height: ceil(50%-8px);

            &.first_top {
              background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1), rgba(255, 93, 59, 1));
              /* Safari 5.1 - 6.0 */
              background: -o-linear-gradient(bottom, rgba(255, 255, 255, 1), rgba(255, 93, 59, 1));
              /* Opera 11.1 - 12.0 */
              background: -moz-linear-gradient(bottom, rgba(255, 255, 255, 1), rgba(255, 93, 59, 1));
              /* Firefox 3.6 - 15 */
              background: linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 93, 59, 1));
            }
          }

          &.line_bottom {
            bottom: 0;
            height: ceil(50%+10px);
          }
        }

        .point {
          position: relative;
          width: 9px;
          height: 9px;
          border-radius: 50%;
          border: 1px solid rgb(255, 75, 39);
          transform: translateY(-50%);
          z-index: 99;
          background-color: rgb(255, 255, 255);

          &::after {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: rgb(255, 75, 39);
            width: 5px;
            height: 5px;
            border-radius: 50%;
          }
        }


      }

      .right {
        width: 100%;
        padding-bottom: 20px;

        .approve_panel {
          margin: 0 15px;
          border-radius: 8px;
          background-color: rgb(255, 255, 255);
          padding: 10px;

          .total_color{
            padding-top: 10px;
            font-weight: bolder;
            span {
              font-weight: normal;
              color: rgb(82, 82, 82);
            }
            .floatR {
              float: right !important;
            }
            .small_font {
              font-size: 12px !important;
            }
          }

          .approve_Text{
            padding-top: 10px;
            font-weight: normal !important;
            color: rgb(82, 82, 82);
            div{
              padding: 0.1rem 0rem;

              .head_sculpture{
                margin-right: 0.1rem;
              }
            }
          }

          //div {
          //  padding-top: 10px;
          //  font-weight: bolder;
          //
          //  &:last-of-type {
          //    padding-bottom: 10px;
          //  }
          //
          //
          //  span {
          //    font-weight: normal;
          //    color: rgb(82, 82, 82);
          //  }
          //
          //  .small_font {
          //    font-size: 12px !important;
          //  }
          //
          //  .floatR {
          //    float: right !important;
          //  }
          //}
        }
      }
    }

  }
}

.bottom-fixed{
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 1.7rem;
  display: flex;
  align-items: center;
  justify-content: right;
  background-color: rgba(250, 250, 250, 1);
  box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.07);
}
.bottom-fixed-orderLook{
  width: 91px;
  height: 40px;
  line-height: 43px;
  margin-right: 15px;
  margin-left: 15px;
  text-align: center;
  border-radius: 8px;
  background: linear-gradient(to right,#7cc7ff,#3596fd);
  box-shadow: 0px 1px 1px 0px rgba(0, 115, 202, 0.5);
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-family: PingFangSC-Semibold;
}

.answerBox{
  box-sizing: border-box;
  padding: 0 0.5rem 0.2rem;
  //overflow: scroll;
}
.answer{
  padding: 0.2rem 0.3rem 0.3rem;
  background-color: white;
  margin-bottom: 0.2rem;
  border-radius: 0.2rem;
}
.answerTitle{
  font-size: 0.4rem;
  font-weight: bold;
  margin: 0.1rem 0 0.2rem;
}
.answerText{
  font-weight: bold;
  font-size: 0.38rem;
  text-indent: 2em;
  color: #999999;
  margin-bottom: 0.2rem;
}
.answerText span{
  //color: black;
  color: rgb(255, 75, 39);
  font-weight: normal;
  //text-decoration: underline;
}
.contentText{
  font-size: 0.38rem;
  text-indent: 2em;
  color: #999999;
  font-weight: bold;
  margin-bottom: 0.2rem;

}
.contentText span{
  font-weight: normal;
  //text-decoration: underline;
}
</style>